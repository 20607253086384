import React, { useContext, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
// import { useTranslation } from "react-i18next";

import { GlobalContext, SurveyContext } from "../../store/contexts/Contexts";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, ButtonBase, Grid, Typography } from "@material-ui/core";
import arrowUpImage from "../../assets/images/arrow-up.png";
import arrowDownImage from "../../assets/images/arrow-down.png";

import FirstPageIcon from "@material-ui/icons/FirstPage";

import { mergeArrays } from "../../utils/helpers";

import { grey } from "@material-ui/core/colors";
import QuestionTypeOne from "./QuestionTypeOne";
import QuestionTypeThree from "./QuestionTypeThree";
import { TYPE_THREE } from "../../data/QuestionsData";
// import { getFieldObject } from "../../store/reducers/ACF_FilterAnswers_Mapper";

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    width: "100%",
    maxWidth: 400,
    maxHeight: 600,
    paddingTop: 20,
    margin: "auto",
  },
  finishBox: {
    maxWidth: "70vw",
    textAlign: "center",
  },
  finishBtn: {
    maxWidth: 250,
    color: "white",
    fontSize: "0.9rem",
    fontWeight: 800,
    borderRadius: "1.5vh",
    textTransform: "none",
  },
  arrowsBox: {
    display: "flex",
    width: "100%",
    textAlign: "center",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    backgroundColor: theme.palette.primary.main,
    //height: "10vh",
  },
  arrowBtn: {
    borderColor: grey[200],
    borderStyle: "solid",
    borderWidth: 1,
    borderRadius: "1.5vh",
    backgroundColor: "white",
    color: theme.palette.primary.main,
    margin: 5,
    width: 40,
    height: 40,
  },
  disabledButton: {
    color: grey[100],
  },
  bottomText: {
    color: "white",
    fontWeight: "bold",
    paddingLeft: 10,
    paddingRight: 10,
  },
  multipageBox: {
    textAlign: "center",
    bottom: "0px",
  },
}));

export default function Survey({ saveSurvey, onSelectAnswer, onPaginate, onLastQuestion, onToggleSurveyMode }) {
  const {
    finishedSurvey,
    selectedAnswers,
    setSelectedAnswers,
    toggleSurveyMode,
  } = useContext(GlobalContext);
  const { questions, currentQuestion, setCurrentQuestion } =
    useContext(SurveyContext);

  const [direction, setDirection] = useState(1);

  const classes = useStyles();

  // const { t } = useTranslation();

  const question = questions[currentQuestion];
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [previousAnswer, setPreviousAnswer] = useState(null);

  const didSelectAnswer = (answer) => {
    const sAnswer = {
      question: question,
      answer: answer,
      // value between 0 and 1
      answerWeight: !Array.isArray(answer)
        ? answer.value / question.answers.length // type one question - 0..4 / 4
        : answer.length > 0
        ? 1 // type three question - every subject gets weight 1
        : 0,
    };

    // const fields = getFieldObject([sAnswer]);
    //console.log("Survey.didSelectAnswer fields:", fields, sAnswer);
    saveSurvey(false, false);

    setPreviousAnswer(sAnswer);

    if (onSelectAnswer) onSelectAnswer(question.field, sAnswer.answer );

    setTimeout(() => {
      const newAns = [sAnswer];
      var tempSelectedAnswersArray = mergeArrays(
        selectedAnswers,
        newAns,
        "question",
        "field"
      );
     //console.log("Selected Answ didSelectAnswer", tempSelectedAnswersArray);
      setSelectedAnswers(tempSelectedAnswersArray);

      //question.type !== TYPE_THREE && nextQuestion();
    }, 10);
  };

  const paginate = (newDirection) => {
    setDirection(newDirection);
    if (onPaginate) onPaginate(newDirection)
  };

  const prevQuestion = () => {
    setCurrentQuestion(currentQuestion - 1);
    paginate(-1);
  };

  const nextQuestion = (plus = 1) => {
    paginate(1);
    if (isLastQuestion) {
    } else {
      const next =
        currentQuestion + plus >= questions.length - 1
          ? questions.length - 1
          : currentQuestion + plus;
      if (onLastQuestion) onLastQuestion();
      setCurrentQuestion(next);
    }
  };

  const checkSelectedAnswer = () => {
    const answer = selectedAnswers.find((answer) => {
      // question.id is not available when loaded answers from backend
      return answer.question.field === question.field;
    });
    if (answer != null) {
      setPreviousAnswer(answer);
    }
  };

  const checkForPreviousAnswers = () => {
    setPreviousAnswer(null);
    checkSelectedAnswer();
  };

  useEffect(() => {
    window.scrollTo(0,0);
    checkForPreviousAnswers();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsLastQuestion(currentQuestion >= questions.length - 1);
    checkForPreviousAnswers();

    // eslint-disable-next-line
  }, [currentQuestion]);

  const isNextArrowDisabled = () => {
    return currentQuestion >= selectedAnswers.length || isLastQuestion;
  };

  //console.log("Survey ... render currentQuestion:",currentQuestion, previousAnswer, selectedAnswers)
  return (
    <AnimatePresence>
      <motion.div
        key={question.id}
        initial={{ top: 1000 * direction, opacity: 0 }}
        animate={{ top: 40, opacity: 1 }}
        transition={{ delay: 0.3 }}
        exit={{ top: -1000 * direction, opacity: 0 }}
        style={{ position: "absolute", left: "0px" }}
      >
        <div style={{ width: "100vw" }}>
          <Grid container direction="row" className={classes.mainGrid}>
            {question.type === TYPE_THREE ? (
              <QuestionTypeThree
                question={question}
                previousAnswer={previousAnswer}
                callBack={didSelectAnswer}
                
              />
            ) : (
              <QuestionTypeOne
                question={question}
                previousAnswer={previousAnswer}
                callBack={(_answer) => {window.scrollTo({
                  top: 1000,
                  left: 0,
                  behavior: 'smooth'
                }); didSelectAnswer(_answer);}}
                prevQuestion={prevQuestion}
                nextQuestion={nextQuestion}
              />
            )}
            <Grid item xs={12}>
              <Box className={classes.arrowsBox}>
                <Button
                  disabled={currentQuestion < 1}
                  className={classes.arrowBtn}
                  style={{
                    opacity: `${currentQuestion < 1 ? "0.3" : "1.0"}`,
                  }}
                  onClick={() => {
                    prevQuestion();
                  }}
                >
                  Zurück
                </Button>
                {!isLastQuestion && (
                  <Box component="span">
                    <Button
                      disabled={isNextArrowDisabled()}
                      className={classes.arrowBtn}
                      style={{
                        opacity: `${isNextArrowDisabled() ? "0.3" : "1.0"}`,
                      }}
                      onClick={() => nextQuestion()}
                    >
                      Weiter
                    </Button>
                  </Box>
                )}

                {/* {finishedSurvey} */}
                {finishedSurvey && // jump to end only if survey is done once
                  !isLastQuestion && (
                    <Box component="span">
                      <ButtonBase
                        className={classes.arrowBtn}
                        style={{
                          opacity: "1.0",
                        }}
                        onClick={() => {nextQuestion(questions.length)}}
                      >
                        <FirstPageIcon
                          style={{
                            transform: "rotate(-90deg)",
                            color: "#999",
                          }}
                        />
                      </ButtonBase>
                    </Box>
                  )}

                {isLastQuestion && (
                  <Box className={classes.finishBox}>
                    <Button
                      className={classes.finishBtn}
                      variant="contained"
                      color="primary"
                      disabled={currentQuestion >= selectedAnswers.length}
                      onClick={() => saveSurvey(true, true)}
                    >
                      Hier gehts zu den Ergebnissen
                    </Button>
                  </Box>
                )}
                {!isLastQuestion && (
                  <Box>
                    <Typography className={classes.bottomText}>
                      Frage {question.id}/{questions.length}
                    </Typography>
                  </Box>
                )}
              </Box>
              
            </Grid>
          </Grid>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
