//const defaultMsg = "Schätze dich ein auf einer Skala von 1-5 in den Bereichen:";
import { mIcon, FOCUS, SUBJECT } from "../utils/helpers";

export const TYPE_ONE = "question_of_type_one";
export const TYPE_TWO = "question_of_type_two";
export const TYPE_THREE = "question_of_type_three";


export const _approaches = [
  {
    id: 1,
    field :"freund",
    text: "Es sind nur noch drei Tage bis zum Examen. Ein guter Freund, den du 2 Jahre nicht gesehen hast, möchte sich mit dir abends in einer Bar treffen. Was tust du? ",
    answers: [
      { id: 4, value: "bbb", text: `Du schickst ihm deine E-Mail-Adresse 
      mit dem Hinweis, sich nächste 
      Woche zu melden. Dann versinkst du 
      wieder in deinen Büchern.` },
      { id : 3, value: "aab", text: `Du klappst direkt dein Schnelllerner-
      Skript zu, von dem du bereits 2 Seiten 
      überflogen hast und bist erst um 3.00 
      Uhr früh zurück. Das Katerfrühstück 
      danach ist obligatorisch.` },
      { id: 2, value: "bcc", text: `Du freust dich! Und überredest ihn sich bei dir zu treffen. Nach einer kurzen Begrüßung hälst du ihm einen Vortrag über den Ablauf der 1. und 2. meiotischen Teilung.` },
      { id: 1, value: "bdd", text: `Du gehst hin, aber unter dem Tisch kreuzt du ständig Examensfragen und malst biochemische Formeln auf Bierdeckel. Nach einer halben Stunde verschwindet er aufs Klo und kommt nicht wieder.` },
    ],
  },
  {
    id: 2,
    field: "bib",
    text: `Du gehst zum Lernen in die Bibliothek - was hast du in der Tasche?`,
    answers: [
      { id: 4, value: "ddd", text: `Du bringst deine selbstgekauften Bücher mit. Denn nur dann kannst du in ihnen mit deinen Textmarkern einen individuellen Style verpassen.` },
      { id: 3, value: "abb", text: `Die Bibliothek kennst du eigentlich nur aus Erzählungen. Als du einmal davor standst schlug dir das Herz bis zum Hals.` },
      { id: 2, value: "ccc", text: `Drei Packungen Kekse für die Lerngruppe, zwei Flaschen Cola und natürlich dein Smartphone.` },
      { id: 1, value: "aac", text: `Das wichtigste ist dein Smartphone. Ansonsten bist du nur in der Bib, weil die süße Kommilitonin / der süße Kommilitone immer am gleichen Tisch dir gegenüber sitzt.` },
    ],
  },
  {
    id: 3,
    field: "motto",
    text: "Welches Motto passt zu dir?",
    answers: [
      { id: 4, value: "acc", text: "Mut zur Lücke!" },
      { id: 3, value: "bbd", text: "Wer zu spät lernt, den bestraft das Leben!" },
      { id: 2, value: "aaa", text: "Wer Arbeiten kann, der kann auch Feiern!" },
      { id: 1, value: "ddd", text: "Ein Bild sagt mehr als tausend Worte!" },
    ],
  },
  {
    id: 4,
    field: "schlafen",
    text: "Heute ist Prüfung! In der Früh reißt dich der Wecker aus dem Tiefschlaf. Wovon könntest du gerade geträumt haben?",
    answers: [
      { id: 4, value: "bbb", text: "Du sitzt in einem riesigen Raum voller Bücher. Alleine. Jedes Buch musst du lesen. Gerade, als du das letzte Buch zuklappst, verwandeln sich die Bücher sprechende Monster, die nach dir schnappen und auf dich zu wackeln..." },
      { id: 3, value: "acc", text: " Du bist peinlich berührst und willst es nicht wirklich sagen. Es geht in dem Traum um jemanden aus deiner Lerngruppe..." },
      { id: 2, value: "bdd", text: "Buchstaben, Bilder, Diagramme schwirren dir vor den Augen. Nichts passt zusammen oder scheint einen Sinn zu ergeben. Als du aufwachst bist du überzeugt, alles vergessen zu haben, das du in den letzten Wochen gelernt hast." },
      { id: 1, value: "aaa", text: "Du stehst mit anderen vor dem angesagtestem Club. Alle wollen rein. Doch die Türsteher sind unerbittlich. Du trittst vor. Die Menge teilt sich und die Türsteher begrüßen dich mit Verbeugung. Denn du bist der Clubbesitzer." },
    ],
  },
  {
    id: 5,
    field: "serie",
    text: "Welche Arzt-Serie ist deine Lieblingsserie?",
    answers: [
      { id: 4, value: "bbd", text: "Dr. House natürlich, ich schaue sie mit Notizblock und Pschyrembel auf dem Schoß." },
      { id: 3, value: "acd", text: "Grey's Anatomy. Patrick Dempsey bzw. Sara Ramirez Anatomie sind einfach unschlagbar. " },
      { id: 2, value: "cdd", text: "Keine mehr. Du hast alles schon mal gesehen und wenn du etwas neues sehen möchtest, dann legst du dich aufs Bett, schließt die Augen und setzt sie vor deinem geistigen Auge aus den gesehenen Serien zusammen." },
      { id: 1, value: "aac", text: "Scrubs natürlich. Am Besten geschaut mit einem Kasten Bier und ein paar Freunden. Perfekt!" },
    ],
  },
  {
    id: 6,
    field: "termine",
    text: "Wie merkst du dir deine Prüfungstermine?",
    answers: [
      { id: 4, value: "bbd", text: "Steht alles mit Raumangabe in meinem Kalender. " },
      { id: 3, value: "cdd", text: "Im Kopf sind sie - das reicht völlig!" },
      { id: 2, value: "aab", text: "Da war doch irgendwo dieser Zettel…??" },
      { id: 1, value: "acc", text: "Easy. Meine Freunde erinnern mich schon rechtzeitig." },
    ],
  },
  {
    id: 7,
    field: "athen",
    text: "Nach der Prüfung belohnst du dich mit einem Wochenende in Athen. Du warst noch nie in der griechischen Hauptstadt. Wie findest du dich in der fremden Stadt zurecht?",
    answers: [
      { id: 4, value: "bbb", text: "Zwei Drittel meines Gepäcks bestehen aus Kulturführern und griechischer Kunstgeschichte. Aber für Abends hast du dir auch schon ein Kompendium über das im nächsten Semester mitgebracht." },
      { id: 3, value: "acc", text: "Du packst ein Griechisch-Mini-Wörterbuch ein und fährst einfach mal los." },
      { id: 2, value: "aac", text: "Du steigst aus dem Flieger und rufst Sottoria/Sottorios an, den/die du letztes Jahr bei der griechischen Nacht an der Uni ...öhm... kennengelernt hast." },
      { id: 1, value: "ddd", text: "Du schaust dir die Stadt einmal in Google Maps von oben an. Dann fährst du los. In Athen gehst du zuerst zur Akropolis und versuchst von dort die Stadt in konzentrischen Kreisen zu erwandern." },
    ],
  }
];



export const _approachesByField = _approaches
  .reduce((acc,app) => {
    acc[app.field] = app;
    return acc;
    },{});


export const questions = [

  ..._approaches

];

