// import MBottomNavigation from "../components/MBottomNavigation";
import { makeStyles } from "@material-ui/core";
import React from "react";
import HeaderFD from "../components/Header";
import Footer from "../components/Footer";
import { useTracking } from "../utils/tracking";
// import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      maxWidth: 1200,
      margin: "auto",
    },
    toolbar: theme.mixins.toolbar,
  };
});

export default function MainLayout({ title, children }) {
  const classes = useStyles();

  const { trackEvent} = useTracking();

  return (
    <div className={classes.root}>
      
      <HeaderFD title={title} onHeaderClick={(title) => trackEvent({ category: 'header',
                                                                  action:   'link',
                                                                  name:     title
                                                                })} />
      <div className={classes.toolbar} />
      <div style={{minHeight: "calc(100vh - 70px)"}}> 
        {/* 70px is height of header (60px and footer 10px) */}
      {children}
      </div>
      {/* <div className={classes.toolbar} /> */}
      {/* {isTabletOrMobileDevice && <MBottomNavigation />} */}
      {/* <Footer /> */}
    </div>
  );
}
