import React, { useContext, useEffect, useState } from "react";

import { GlobalContext } from "../../store/contexts/Contexts";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { Box, CircularProgress, Container, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router";
import {
  APPOINTMENT_CONFIRMED,
  CONFIRM_APPOINTMENT,
} from "../../store/reducers/AppointmentReducer";
import SalonService from "../../services/SalonService";
import { useTracking } from "../../utils/tracking";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 60,
    maxWidth: 600,
  },
  title: {
    widht: "100%",
    fontSize: "1.2rem",
    fontWeight: 500,
    textAlign: "left",
  },
  description: {
    widht: "100%",
    fontSize: "1.0rem",
    fontWeight: 600,
    textAlign: "left",
    marginBottom: 20,
  },
  timeBtn: {
    width: "100%",
    height: 50,
    fontSize: "1.2rem",
    color: theme.palette.primary.main,
  },
  form: {
    width: "100%",
  },
  textfield: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  submit: {
    marginTop: 20,
    textAlign: "center",
    fontSize: "1.0rem",
    fontWeight: 800,
    color: "white",
    textTransform: "none",
  },

  imageBox: {
    width: "100%",
    height: 180,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: theme.palette.primary.light,
  },
  media: {
    width: 80,
    height: 80,
    display: "flex",
    textAlign: "center",
    borderRadius: 40,
    borderWidth: 1,
    borderColor: "white",
    borderStyle: "solid",
  },
  name: {
    fontSize: "1.4rem",
    fontWeight: 600,
    marginTop: 10,
    display: "flex",
  },
  coachTxt: {
    fontSize: "1.0rem",
    marginBottom: 5,
    display: "flex",
  },
}));

const initialFormValues = {
  firstName: "",
  surname: "",
  email: "",
  message: "",
  formSubmitted: false,
  success: false,
};

const inputFieldValues = [
  {
    name: "name",
    label: "Vorname",
    id: "firstName",
  },
  {
    name: "surname",
    label: "Nachname",
    id: "surname",
  },
  {
    name: "email",
    label: "Email",
    id: "email",
  },
];

export default function AppointmentInfo() {

  

  const { usersState, appointmentState, appointmentDispatch } =
    useContext(GlobalContext);

  const [values, setValues] = useState({ ...initialFormValues, 
                                            email: usersState.userEmail, 
                                            surname:usersState.lastName, 
                                            firstName: usersState.firstName });
  
  
  
                                            // const [values, setValues] = useState([...initialFormValues.filter(e => e.name !== 'email')]);
  const [errors, setErrors] = useState();

  const classes = useStyles();
  const history = useHistory();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    if (appointmentState.confirmAppointment) {
      createBooking();
    }
    // eslint-disable-next-line
  }, [appointmentState]);

  const {trackPage} = useTracking(); 
  useEffect(() => {
    if (appointmentState) {
      trackPage();
    }
  }, [appointmentState])


  const validate = (fieldValues) => {
    let temp = { ...errors };

    //console.log(fieldValues);
    if (fieldValues.email) {
      temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
        ? ""
        : "Email is not valid.";
    }
    // //console.log(temp);
    setErrors({
      ...temp,
    });
    return temp.email === "";
  };

  const errorForField = (fieldValues) => {
    // //console.log(fieldValues);
    const { id } = fieldValues;

    if (id === "email" && errors) {
      const err = Object.values(errors).every((element) => element === "");
      if (!err) {
        return errors.email;
      }
    }
    return null;
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (validate(values)) {
     //console.log(values);
      appointmentDispatch({
        type: CONFIRM_APPOINTMENT,
        payload: {
          userId: usersState.userId,
          firstname: values.firstName,
          lastname: values.surname,
          email: values.email,
          notes: values.message,
        },
      });
    } else {
      console.warn("Error: - - - -" + errors);
    }
  };

  function createBooking() {
    SalonService.bookAppointment(appointmentState, (bookingId) => {
     //console.log("bookingId");
     //console.log(bookingId);
      if (bookingId) {
        appointmentDispatch({ type: APPOINTMENT_CONFIRMED });
          history.push({
            pathname: "/appointment-details/", // + bookingId,
            state: { bookingId: bookingId, appointmentConfirmed: true },
          });
      } 
    });
  }

  const handleInputValues = (e) => {
    const { id, value } = e.target;

    setValues({
      ...values,
      [id]: value,
    });
  };

  return (
    <Container className={classes.root}>
      {appointmentState.selectedDate && (
        <Grid container spacing={2} className={classes.content}>
          <Grid item xs={12}>
            <Typography className={classes.title}>Termin bestätigen</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.description}>
              {appointmentState.selectedDate.toLocaleString("de-DE", options)}
            </Typography>
          </Grid>
          <form className={classes.form} onSubmit={handleFormSubmit}>
            {inputFieldValues.map((inputFieldValue, index) => {
              return (
                <Grid key={index} item xs={12}>
                  <TextField
                    required
                    style={{ width: "100%" }}
                    id={inputFieldValue.id}
                    label={inputFieldValue.label}
                    onChange={handleInputValues}
                    className={classes.textfield}
                    value={values[inputFieldValue.id]}
                    error={errorForField(inputFieldValue) !== null}
                    helperText={errorForField(inputFieldValue)}
                  />
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <TextField
                style={{ width: "100%" }}
                id="message"
                label="Gibt es etwas, dass du mir schon mitteilen möchtest?"
                on
                onChange={handleInputValues}
                className={classes.textfield}
                multiline={true}
              />
            </Grid>
            <Grid item xs={12}>
              {appointmentState.loading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="34"
                  width="34"
                  marginTop="20"
                >
                  <CircularProgress disableShrink color="primary" />
                </Box>
              ) : (
                <Box width="100%" textAlign="center" marginTop="20">
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Bestätigen
                  </Button>
                </Box>
              )}
            </Grid>
          </form>
        </Grid>
      )}
    </Container>
  );
}
