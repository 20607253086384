import React, { useEffect, useReducer, useState } from "react";

import CoachService from "../../services/CoachService";
import UserService from "../../services/UserService";
import SalonService from "../../services/SalonService";

import { useHistory } from "react-router-dom";

import {
  coachesInitialState,
  CoachesReducer,
  GET_COACHES,
  SET_COACHES,
  SORT_COACHES,
} from "../reducers/CoachReducer";
import {
  filterInitialState,
  FilterReducer,
  ANSWER_QUESTION,
} from "../reducers/FilterReducer";
import {
  UserReducer,
  usersInitialState,
  DO_LOGOUT,
  DO_LOGIN,
  DONE_LOGIN,
  SAVING_CUSTOM_FIELDS,
  SET_CUSTOM_FIELDS,
  SAVED_CUSTOM_FIELDS,
} from "../reducers/UserReducer";

import { GlobalContext } from "./Contexts";
import { getAnswerObjects, hasValidAnswers } from "../reducers/ACF_FilterAnswers_Mapper";
import {
  appointmentInitialState,
  AppointmentReducer,
} from "../reducers/AppointmentReducer";
import axios from "axios";
import { useMatomo } from "@datapunt/matomo-tracker-react";

export default function GlobalContextProvider({ children }) {
  const [coachesState, coachesDispatch] = useReducer(
    CoachesReducer,
    coachesInitialState
  );

  const [appointmentState, appointmentDispatch] = useReducer(
    AppointmentReducer,
    appointmentInitialState
  );

  const [filterState, filterDispatch] = useReducer(
    FilterReducer,
    filterInitialState
  );

  const [selectedCoach, setSelectedCoach] = useState();
  const [usersState, usersDispatch] = useReducer(
    UserReducer,
    usersInitialState
  );

  const [surveymode, setSurveymode] = useState("multipage");

  const { pushInstruction } = useMatomo();

  const toggleSurveyMode = () => {
    //console.log("toggleSurveyMode", surveymode);
    surveymode === "multipage"
      ? setSurveymode("onepage")
      : setSurveymode("multipage");

    
  };

  const [urls, setUrls] = useState([]);
  const history = useHistory();
  useEffect(() => {
    return history.listen((location) => {
      //console.log(" last url...", urls.length > 0 ? urls[urls.length -1] : undefined);

      // do not add if it is the same as last ( >>> history.goBack also fires this history.listen(...) )
      if (urls.length > 0 && urls[urls.length - 1] === location.pathname) {
        return;
      }
      //console.log(`You changed the page to: ${location.pathname}`, urls)

      // add next path to keept track of users navigation:
      const urlsNew = [...urls, location.pathname];
      setUrls(urlsNew);

      //console.log("addNextUrl", location.pathname, urlsNew, urls);
    });

    // eslint-disable-next-line
  });
  const hasNavigated = () => {
    // if there are urls, the user has navigated
    return urls.length > 0;
  };
  /**
   * use this goBack, so we can keep track of user navigation!
   */
  const goBack = () => {
    urls.pop();
    //console.log("goBack", urls);
    setUrls([...urls]);

    // if there is no former goBack - we "goBack" to startpage!
    if (urls.length > 0) {
      return history.goBack();
    } else {
      return history.push("/");
    }
  };

  const setSelectedAnswers = (state) => {
    localStorage.setItem(
      "selectedAnswers",
      JSON.stringify(state)
    );
    setSelectedAnswersss(state);
  };

  const [selectedAnswers, setSelectedAnswersss] = useState([]);
  const [finishedSurvey, setFinishedSurvey] = useState(false);

  useEffect(() => {

    const localSelectedAnswers = JSON.parse(localStorage.getItem("selectedAnswers"));
    if (localSelectedAnswers && localSelectedAnswers !== "") setSelectedAnswers(localSelectedAnswers);
   //console.log("useEffect - filterState:",filterState)
    filterDispatch({ type: ANSWER_QUESTION, payload: localSelectedAnswers });
   
  }, []);

  useEffect(() => {
    if (
      filterState.personalSorting ||
      filterState.subject != null ||
      filterState.focus != null
    ) {
     //console.log("--filterState", filterState)
      coachesDispatch({ type: SORT_COACHES, payload: filterState });
      
    }

    // eslint-disable-next-line
  }, [filterState]);

  const getCoaches = () => {
    return new Promise(function (finished) {
      coachesDispatch({ type: GET_COACHES });
      CoachService.getCoaches((coachesRepo) => {
        coachesDispatch({ type: SET_COACHES, payload: coachesRepo });
        finished();
      });
    });
  };

  const checkIfUserIsLoggedIn = () => {
    return new Promise(function (finished) {
      UserService.isUserLoggedIn().then((data) => {
        if (data) {
          usersDispatch({ type: DONE_LOGIN, payload: data });
          if (data.acf) {
            settingCustomFields(data.acf);
          }
        }
        finished();
      });
    });
  };

  const userLogout = () => {
    UserService.logout();
    SalonService.logout();
    usersDispatch({ type: DO_LOGOUT });
    settingCustomFields([]);
    setFinishedSurvey(false);
    
  };

  const userLogin = (username, password) => {
    usersDispatch({
      type: DO_LOGIN,
      payload: {
        username,
        password,
      },
    });
    UserService.login(username, password).then((data) => {
      usersDispatch({ type: DONE_LOGIN, payload: data });

      if (data && data.acf) {
       //console.log("userLogin data.acf", data.acf);
        settingCustomFields(data.acf);
        pushInstruction('setUserId', data.id);
      }
      
      
    });
    SalonService.loginToSalon(username, password).then( ({login, token}) => {
      if (login) {axios.defaults.headers.common["access-token"] = token;}
      else {console.error("loginToSalon not successfull");}
    })
  };

  const settingCustomFields = (dataFields) => {
    usersDispatch({
      type: SET_CUSTOM_FIELDS,
      payload: { fields: dataFields },
    });
    const answers = getAnswerObjects(dataFields);
    setSelectedAnswers(answers);

    const validAnswers = hasValidAnswers(answers)
    if (validAnswers ) {
      filterDispatch({ type: ANSWER_QUESTION, payload: answers })
      setFinishedSurvey(true);
    }
  };

  const userSaveCustomFields = (fields) => {
    //if (true) return;

    if (!usersState.loggedIn) {
      return new Promise(function (resolve, reject) {
        reject("not logged in");
      });
    }

    //console.log("GlobalContextProvider.userSaveCustomFields", fields);
    //console.log("GlobalContextProvider.userSaveCustomFields", fields);
    return new Promise(function (resolve, reject) {
      usersDispatch({ type: SAVING_CUSTOM_FIELDS });
      // console.log(
      //   "GlobalContextProvider.userSaveCustomFields saveCustomFields",
      //   usersState.userId,
      //   fields
      // );
      UserService.saveCustomFields(usersState.userId, fields).then(
        (data) => {
          // console.log(
          //   "GlobalContextProvider.userSaveCustomFields CF saved",
          //   usersState.userId,
          //   data
          // );
          if (data && data.acf) {
            usersDispatch({ type: SAVED_CUSTOM_FIELDS, payload: data.acf });
            // usersDispatch({  // not necessary to set again
            //   type: SET_CUSTOM_FIELDS,
            //   payload: data.acf,
            // });
            resolve(data);
          } else {
            reject("no data");
          }
        },
        (error) => {
          // usersDispatch({
          //   type: SAVE_CUSTOM_FIELDS,
          //   payload: { fields: null },
          // });
          reject(error);
        }
      );
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        coachesState,
        coachesDispatch,
        appointmentState,
        appointmentDispatch,
        getCoaches,
        filterState,
        filterDispatch,
        usersState,
        usersDispatch,
        userLogin,
        userLogout,
        userSaveCustomFields,
        selectedAnswers,
        setSelectedAnswers,
        finishedSurvey,
        setFinishedSurvey,
        selectedCoach,
        setSelectedCoach,

        hasNavigated,
        goBack,
        urls,

        surveymode,
        setSurveymode,
        toggleSurveyMode,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}
