export const BASE_DOMAIN = "https://www.mentor-app.de";

export const BASE_URL = BASE_DOMAIN + "/wp-json";
// export const BASE_URL_SALON = "/wp-json";

export const GET_COACHES_PATH = "/wp/v2/product?&per_page=20&ptype=74";
export const GET_MEDIA_PATH = "/wp/v2/media/";

export const SALON_API = "/salon/api/v1";
export const LOGIN_TO_SALON_PATH = SALON_API + "/login";
export const GET_COACHES_FROM_SALON_PATH = SALON_API + "/assistants";
export const GET_COACH_AVAILABILITY_PATH =
  SALON_API + "/availability/intervals";
export const GET_COACH_EXTERNAL_FREEBUSY_PATH = "/cdgooglecalendar/v1/freebusy/";
export const BOOKINGS_APPOINTMENT_PATH = SALON_API + "/bookings";
export const DEFAULT_SERVICE_ID =  23566 // Coach erstgespraech

export const GET_USER_ME = "/wp/v2/users/me/";
export const POST_LOGIN = "/jwt-auth/v1/token";
export const POST_USER_ACF = "/acf/v3/users/";
