import React, { useContext, useEffect, useState } from "react";

import { SurveyContext, GlobalContext } from "../../store/contexts/Contexts";
import { ANSWER_QUESTION } from "../../store/reducers/FilterReducer";
// import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FinderIntro from "../../components/coach-finder/FinderIntro";
import Survey from "../../components/coach-finder/Survey";
import SurveyOnePage from "../../components/coach-finder/SurveyOnePage";

import { getFieldObject } from "../../store/reducers/ACF_FilterAnswers_Mapper";
import {  useTracking } from "../../utils/tracking";


// const useStyles = makeStyles((theme) => ({
//   root: {
//     // maxWidth: 600,
//   },
//   finishBox: {
//     width: "100%",
//     backgroundColor: "white",
//     textAlign: "center",
//     position: "-webkit-sticky",
//     // position: "sticky",
//     bottom: "0px",
//   },
//   multipageBox: {
//     textAlign: "right",
//     position: "-webkit-sticky",
//     // position: "sticky",
//     bottom: "0px",
//   },
//   multipageBoxMulti: {
//     textAlign: "right",
//     // position: "absolute",
//     bottom: "0px",
//     right: "0px",
//   },
//   finishBtn: {
//     maxWidth: 250,
//     color: "white",
//     fontSize: "0.9rem",
//     fontWeight: 800,
//     borderRadius: "1.5vh",
//     textTransform: "none",
//     marginBottom: "40px",
//     margin: "20px",
//   },
// }));

export default function CoachFinder() {
  //import ReactGA from 'react-ga4';
  const {trackPage, trackEvent} = useTracking();
  useEffect(() => {
    trackPage()
  }, [])
  
  //const [startScreen, setStartScreen] = useState(true);
  const { currentQuestion, setCurrentQuestion } = useContext(SurveyContext);
  const {
    filterDispatch,
    selectedAnswers,
    finishedSurvey,
    setFinishedSurvey,
    userSaveCustomFields,
    surveymode,
  } = useContext(GlobalContext);

  //const didAnswer = selectedAnswers && selectedAnswers.length > 0;
  const showHannaScreen = !finishedSurvey && currentQuestion < 0;

  // const theme = useTheme();
  // const smDown = useMediaQueryMui(theme.breakpoints.down("sm"));

  const history = useHistory();

  useEffect(() => {
    // >>>>>>>>>>>>>> for testing 1/2
    // const ans = getAnswerObject("coachfinder_approach_futurevision", 4);
    // const anss = [ans];
    // setSelectedAnswers(anss);
    // //console.log("useEffect1 selectedAnswers", anss)
    // eslint-disable-next-line
    //setCurrentQuestion(0);
  }, []);

  useEffect(() => {
    // >>>>>>>>>>>>>> fortesting 2/2
    // saveSurvey(true,false, false)
    // setCurrentQuestion(11)
    // //console.log("useEffect2", selectedAnswers)
    // eslint-disable-next-line
  }, [selectedAnswers]);

  useEffect(() => {
    !showHannaScreen && setCurrentQuestion(0);
    //console.log("CoachFinder showHannaScreen", showHannaScreen);

    // eslint-disable-next-line
  }, [showHannaScreen]);

  // const classes = useStyles();
  // const { t } = useTranslation();

  const saveSurvey = (finalized = false, redirect = false, dispatch = true) => {
    //setSelectedAnswers(selectedAnswers);
    if (!finishedSurvey) setFinishedSurvey(finalized); // once finished - always finished :)
   //console.log("selectedAnswers",selectedAnswers);

    

    if (dispatch)
      filterDispatch({ type: ANSWER_QUESTION, payload: selectedAnswers });

    const fields = getFieldObject([...selectedAnswers]);
    userSaveCustomFields(fields);
    if (redirect) history.replace("/lerntypen");

    trackEvent({ category: 'coachfinder',
                action:   'save-survey'
              })
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <Container
        style={{
          // marginRight: (smDown ? "0px" : "-50px"), /* Maximum width of scrollbar */
          // paddingRight: "50px", /* Maximum width of scrollbar */
          // overflowY: "scroll",
          overflowX: "hidden",
          height: "calc(100% - 100px)", // minus header
          display: "block",
        }}
      >
        {currentQuestion < 0 ? (
          <FinderIntro onStart={trackEvent({ category: 'coachfinder',
                                              action:   'start'
                                            })} />
        ) :(
          <Survey saveSurvey={saveSurvey}
                  onToggleSurveyMode={() => trackEvent({ category: 'coachfinder',
                                                                action:   'togglesurveymode',
                                                                name:     "clicked-inMultipage", 
                                                              })}
                  onLastQuestion={() => trackEvent({ category: 'coachfinder',
                                                                  action:   'paginate-last',
                                                                  name:     "lastquestion"
                                                                })}
                  onPaginate={(direction) => trackEvent({ category: 'coachfinder',
                                                              action:   'paginate',
                                                              name:     "direction", 
                                                              value:    direction, 
                                                            })}
                  onSelectAnswer={(field, answer) => trackEvent({ category: 'coachfinder',
                                                                  action:   'select-answer-multipage',
                                                                  name:     field, 
                                                                  value:    answer.value, 
                                                                  
                                                                })} />
        
        ) }
      </Container>
    </div>
  );
}
