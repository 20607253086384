

import { _approachesByField,  _subjectsQuest } from "../../data/QuestionsData";
import {checkValidAnswer} from './SchemaAnswers';

export function getFieldObject(answers) {
  return answers.reduce(
    (acc, curr) => {
      if (Array.isArray(curr.answer))  {
        curr.answer.forEach((ans => {
          acc[ans.name] =  ans.value;
        }))
      }else{
        acc[curr.question.field] = curr.answer.value;
      }
      //acc[getKeyStringFromField(curr)] = getValueStringFromField(curr.answer);
      return acc;
    }, {});
}


export function getAnswerObjects_old(acf) {

  const result = Object.keys(acf).map((key) => getAnswerObject(key, acf[key]));


  //console.log("getAnswerObjects", acf, result,result2)
  return result;
}


export function hasValidAnswers(answers){
  if (!answers || ! answers.length || answers.length === 0){
    return false;
  }
  const validity = answers.map(answer =>  checkValidAnswer(answer));
  if (true === validity.reduce((result, entry) => {
      return result || entry.error; // true if one error exists
    }, false))
  {
     console.error("hasValidAnswers validity error:", validity);
     return false;
  }
  const checkOneAnswer = (ansValObj) => ansValObj && ansValObj.value !== undefined && ansValObj.value !== null && ansValObj.value > -1 ;
  const hasAnswerValue = (answer) => {
    if (Array.isArray(answer.answer)){
      return answer.answer.reduce((result, innerAnswer) => {
        return result || checkOneAnswer(innerAnswer)
      } , false);
    }else {
      const innerAnswer = answer.answer;
      return checkOneAnswer(innerAnswer);
    }
  }
  return answers.reduce((result, answer) => {
    return result || hasAnswerValue(answer)
  } , false);

}


/**
 * parsing acf field for coachfinder_approach_x and coachfinder_subject_x fields and returning them in coachfinder answer-structure
 * @param {*} acf 
 */
export function getAnswerObjects(acf) {

 

  return;
}

export function getAnswerObject(question, answer, answerWeight = 1) {

  const answerx = {
    question: question,
    answer: answer,
    answerWeight: answerWeight,

  };
  return answerx;
}

