import ReactGA from "react-ga4";
import { createInstance, useMatomo } from '@datapunt/matomo-tracker-react';
import { truncateSync } from "fs";



const instance = createInstance({
  urlBase: window.location.origin,
  siteId: window.location.host.startsWith("app.mentor") ? 3 : window.location.host.startsWith("beta.mentor") ? 4 : window.location.host.startsWith("future-doctors.mentor") ? 5 : 2 ,
  //userId: 'UID76903202', // optional, default value: `undefined`.
  trackerUrl: 'https://www.mentor-app.de/wp-content/plugins/matomo/app/matomo.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: 'https://www.mentor-app.de/wp-content/plugins/matomo/app/matomo.js', // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `16
  },
  linkTracking: false, // optional, default value: true
  configurations: { } // optional, default value: {}
  // {  // any valid matomo configuration, all below are optional
  //   disableCookies: false,
  //   setSecureCookie: true,
  //   setRequestMethod: 'POST'
  // }
})

export const initTracking = () => {

  if ( window.location.host.startsWith("app.mentor") ){

    // google
    ReactGA.initialize("G-P4B7DF84XB", {
      debug: true,
    });

    // piwik
    
  
    return instance;

  } else {

    return instance;
    // return createInstance({ disabled: true } );
  }
  
}


export function useTracking() {

  const { trackPageView:trackPageViewMatomo, trackEvent:trackEventMatomo } = useMatomo();


  


  const trackPage = (url, customDimensions) => {

   //console.log("** track page:", window.location.href );

    ReactGA.send({ hitType: "pageview", page: window.location.pathname });


    if (trackPageViewMatomo) {

      const href = url ? url : window.location.href;
      trackPageViewMatomo({   href  ,
                              customDimensions
                          });
    };
  }

  const trackEvent = (event)  => {

   //console.log("** track event:", window.location.href, event );

    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    if (trackEventMatomo && event) {
      trackEventMatomo(event);
    }
  }

  return {
    initTracking,
    trackEvent,
    trackPage,
  }

}