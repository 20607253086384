import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Import Views
import MainLayout from "../layouts/MainLayout";
import Home from "../pages/home/Home";
import Intro from "../pages/intro/Intro";
import Coaches from "../pages/coaches/Coaches";
import Lerntypen from "../pages/lerntypen/Lerntypen";

import SortedCoaches from "../pages/sorted-coaches/SortedCoaches";
import CoachDetails from "../pages/coach-details/CoachDetails";
import CoachFinder from "../pages/coach-finder/CoachFinder";
import IFramePage from "../pages/general-page/IFramePage";
import IFrameCoachDetail from "../pages/general-page/IFrameCoachDetail";

import SurveyOnePage from "../components/coach-finder/SurveyOnePage";

import SurveyContextProvider from "../store/contexts/SurveyContextProvider";

// Mising
import { PageNotFound } from "../components/404";
import CalendarPage from "../pages/appointment/CalendarPage";
import TimeSelection from "../pages/appointment/TimeSelection";
import AppointmentInfo from "../pages/appointment/AppointmentInfo";
import Login from "../pages/login/Login";
import MyAppointments from "../pages/my-appointments/MyAppointments";
import AppointmentDetails from "../pages/my-appointments/AppointmentDetails";
import CancelAppointment from "../pages/my-appointments/CancelAppointment";
import StartQuestions from "../pages/coach-finder/StartQuestions";

// import logo from "../assets/images/logo_1.png";

const x = (
  <span
    style={{ alignItems: "center", display: "flex", justifyContent: "center" }}
  >
    {/* <img src={logo}
                      alt="up-arrow"
                      height={30} />  */}
    Coachfinder
  </span>
);

const ROUTES = [
  // {
  //   path: "/",
  //   key: "ROOT",
  //   exact: true,
  //   component: (props) => (
  //     <MainLayout title={x}>
  //       <Intro {...props} />
  //     </MainLayout>
  //   ),
  // },
  {
    path: "/home",
    key: "ROOT",
    exact: true,
    component: (props) => (
      <MainLayout title={x}>
        <Home {...props} />
      </MainLayout>
    ),
  },
  {
    path: "/login",
    key: "ROOT",
    exact: true,
    component: (props) => (
      <MainLayout title={"Lerntypen"}>
        <Login {...props} />
      </MainLayout>
    ),
  },
  {
    path: "/coaches", //  coaches overview + (mobile) coach detail view
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <Coaches {...props} />
        </MainLayout>
      );
    },
  },
  {
    path: "/lerntypen", //  coaches overview + (mobile) coach detail view
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <Lerntypen {...props} />
        </MainLayout>
      );
    },
  },
  {
    path: "/sorted-coaches",
    key: "ROOT",
    exact: true,
    component: (props) => (
      <MainLayout title={"Lerntypen"}>
        <SortedCoaches {...props} />
      </MainLayout>
    ),
  },
  {
    path: "/coaches/:coachSlug", //  coaches overview + (mobile) coach detail view
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <CoachDetails {...props} />
        </MainLayout>
      );
    },
  },
  {
    path: ["/hubspot", "/hubspot/:coachSlug"],
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <IFramePage {...props} />
        </MainLayout>
      );
    },
  },
  {
    path: "/find/coach/:coachSlug", // desktop coach detail view
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <IFrameCoachDetail />
        </MainLayout>
      );
    },
  },
  {
    path: "/",
    key: "ROOT",
    exact: true,
    component: (props) => (
      <StartQuestions />
    ),
  },
  {
    path: "/fragebogen",
    key: "ROOT",
    exact: true,
    component: (props) => (
      <MainLayout title={"Lerntypen"}>
        <SurveyContextProvider>
          <CoachFinder {...props} />
        </SurveyContextProvider>
      </MainLayout>
    ),
  },
  {
    path: "/coach-finder", // redirect form old link
    key: "ROOT",
    exact: true,
    component: (props) => (
      <Redirect to="/coachfinder" />
    ),
  },
  {
    path: "/appointment/:coachSlug",
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <CalendarPage {...props} />
        </MainLayout>
      );
    },
  },
  {
    path: "/surveyonepage",
    key: "ROOT",
    exact: true,
    component: (props) => (
      <MainLayout title={"Lerntypen"}>
        <SurveyOnePage />
      </MainLayout>
    ),
  },
  {
    path: "/time-selection",
    key: "ROOT",
    exact: true,
    component: (props) => (
      <MainLayout title={"Lerntypen"}>
        <TimeSelection {...props} />{" "}
      </MainLayout>
    ),
  },
  {
    path: "/appointment-info",
    key: "ROOT",
    exact: true,
    component: (props) => (
      <MainLayout title={"Lerntypen"}>
        <AppointmentInfo {...props} />
      </MainLayout>
    ),
  },
  {
    path: "/my-appointments",
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <MyAppointments {...props} />
        </MainLayout>
      );
    },
  },
  {
    path: "/appointment-details", // /:bookingId",
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <AppointmentDetails {...props} />
        </MainLayout>
      );
    },
  },
  {
    path: "/cancel-appointment",
    key: "ROOT",
    exact: true,
    component: (props) => {
      return (
        <MainLayout title={"Lerntypen"}>
          <CancelAppointment {...props} />
        </MainLayout>
      );
    },
  },
];
export default ROUTES;

export const RenderRoutes = ({ routes }) => {
  return (
    <Switch>
      {routes.map((route) => {
        return <RouteWithSubRoutes key={route.key} {...route} />;
      })}
      <Route component={(props) => <PageNotFound {...props} />} />
    </Switch>
  );
};

const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      exact={route.exact}
      render={(props) => <route.component {...props} routes={route.routes} />}
    />
  );
};
