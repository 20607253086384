import Api from "../network/Api";

import {
  LOGIN_TO_SALON_PATH,
  GET_COACH_AVAILABILITY_PATH,
  BOOKINGS_APPOINTMENT_PATH,
  DEFAULT_SERVICE_ID,
  SALON_API,
  GET_COACH_EXTERNAL_FREEBUSY_PATH
} from "../network/ApiConstants";



import axios from "axios";




const ACCESS_TOKEN = "access-token";


axios.interceptors.request.use(
   async (request) => {
    //because we need to use two different access token in request header
    //the user auth token and the salon api access token for the appointments
    //we need to intercept the requests and check for all salon requests
    //and add the salon access token to only those requests
    //without changing the axios default header auth token

    //console.log("axios.interceptors request",request);
    if (request.url.includes(SALON_API) && !request.url.toUpperCase().endsWith("/LOGIN")) {

      // *** for testing:
      //if (!axios.defaults.headers.common[ACCESS_TOKEN]) axios.defaults.headers.common[ACCESS_TOKEN] = "test-set-to-wrong-value (like after a day)";
      //localStorage.setItem(LOCAL_SALON_TOKEN,"test-set-to-wrong-value (like after a day)")
      
      // on (re)load of page common[ACCESS_TOKEN] will be empty - so we login:
      const current_salon_token = axios.defaults.headers.common[ACCESS_TOKEN];
      // const current_salon_token = localStorage.getItem(LOCAL_SALON_TOKEN); // <--- TODO: use storage, but then also the loggedIn State should be storaged: 
      
      //console.log("axios.interceptors request current_salon_token",current_salon_token,request.url);
      if (!current_salon_token) {
        //console.log("axios.interceptors request 2 current_salon_token",current_salon_token,request.url);
      
        const loginData = await loginToSalon("testuser", "testuser"); // DEFAULT USER
        if (loginData && loginData.login && loginData.token){
          axios.defaults.headers.common[ACCESS_TOKEN] = loginData.token;
        }
      }else{
        axios.defaults.headers.common[ACCESS_TOKEN] = current_salon_token;
      }
      
      //const localTokenByLogin = localStorage.getItem(LOCAL_SALON_TOKEN);
      //console.log("axios.interceptors localTokenByLogin", localTokenByLogin,request.url)

      request.headers[ACCESS_TOKEN] = axios.defaults.headers.common[ACCESS_TOKEN];
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axios.interceptors.response.use((response) => {
  return response
}, 
async function (error) { // error case
  const originalRequest = error.config;
  //console.log("axios.interceptors.response on-error 1",error,originalRequest._retry, originalRequest.url)
  if ((error.response.status === 401 || error.response.status === 403) 
        && !originalRequest._retry) {
    originalRequest._retry = true;
    //console.log("axios.interceptors response 1 current_salon_token",error.response.status,originalRequest.url);
      
    const loginData = await loginToSalon("testuser", "testuser");
    //console.log("axios.interceptors.response on-error login loginData:1",loginData, originalRequest.url)
    if (loginData && loginData.login && loginData.token){
      //console.log("axios.interceptors.response on-error login loginData2:",loginData, originalRequest.url)
      axios.defaults.headers.common[ACCESS_TOKEN] = loginData.token;
      originalRequest.headers[ACCESS_TOKEN] = loginData.token;
    }         
    return axios(originalRequest);
  }
  return Promise.reject(error);
});

export const coachIntervals = {
  days: [],
  times: [],
};

export const LOCAL_SALON_TOKEN = "salon-" + ACCESS_TOKEN;

export const APPOINTMENT_CONFIRMED = "sln-b-confirmed";
export const APPOINTMENT_CANCELED = "sln-b-canceled";

export const appointmentStatus = (_status) => {
  switch (_status) {
    case APPOINTMENT_CANCELED:
      return "Abgesagt";
    case APPOINTMENT_CONFIRMED:
      return "Gebucht";
    default:
      return "Pending";
  }
};

const parseIntervals = (responseData) => {
  if (!responseData) return coachIntervals;

  const _datesData = responseData.intervals && responseData.intervals.dates;
  const _timesData = responseData.intervals && responseData.intervals.times;
  return { dates: Object.values(_datesData), times: Object.values(_timesData) };
};

async function logout(){
  localStorage.removeItem(LOCAL_SALON_TOKEN);
  delete  axios.defaults.headers.common[ACCESS_TOKEN]
}



async function loginToSalon(name,password) {
      //(name,password,callBack) {
        
  // const name = process.env.REACT_APP_VAR1;
  // const password = process.env.REACT_APP_VAR2;
  const parameters = { name: name, password: password };


  return new Promise((resolve,reject) => {
      Api.get(
        LOGIN_TO_SALON_PATH,
        (loginData) => {
          if (loginData && loginData.status === "OK" && loginData.access_token) {
            //console.log("loginToSalon OK loginData", name, loginData)
            localStorage.setItem(LOCAL_SALON_TOKEN, loginData.access_token);
            resolve({login:true, token:loginData.access_token});
          } else {
            resolve({login:false, token:null});
          }
        },
        (error) => {
          //handle error
          console.warn("Error login: " + error);
          reject(false);
        
        },
        parameters
      );
    });
}

/**
 * get this month freebusy times
 * @param {*} coachslug 
 * @param {*} date 
 * @param {*} callBack 
 */
async function getCoachExternalBusyByMonth(coachslug, date, callBack) {

  const y = date.getFullYear(), m = date.getMonth();

  const start = new Date(y, m - 1, 23);
  const end = new Date(y, m + 1, 7);

  Api.get(
    GET_COACH_EXTERNAL_FREEBUSY_PATH,
    (response) => {
      //here if we need we can parse or change the data
     //console.log("getCoachExternalBusyByMonth",response);
      callBack(response);
    },
    (error) => {
      //handle error
     //console.log(error);
    },
    { coach: coachslug , startdate: start.toISOString(),  enddate: end.toISOString() }
  );
}

async function getCoachAvailability(coachId, date, callBack) {
  //checkIfTokenExist((hasToken) => {
    const time = "14:00";
    const assistant_id = coachId;

    Api.post(
      GET_COACH_AVAILABILITY_PATH,
      { date, time, services : [{ assistant_id, service_id: DEFAULT_SERVICE_ID}] },
      (response) => {
        //here if we need we can parse or change the data
        //console.log(response);

        callBack(parseIntervals(response));
      },
      (error) => {
        //handle error
        console.warn("Error: " + error);
        callBack(false);
      }
    );
  //});
}

async function bookAppointment(appointment, callBack) {
  const id = "0";
  const created = 1528107879;
  const date = appointment.selectedDate.toLocaleDateString("en-CA");
  const time = appointment.selectedTime;
  const status = APPOINTMENT_CONFIRMED;
  const customer_id = appointment.userId;
  const customer_first_name = appointment.firstname;
  const customer_last_name = appointment.lastname;
  const customer_email = appointment.email;
  const services = [
    {
      service_id: 23566,
      assistant_id: appointment.assistantId,
    },
  ];
  const note = appointment.notes;
  Api.post(
    BOOKINGS_APPOINTMENT_PATH,
    {
      id,
      created,
      date,
      time,
      status,
      customer_id,
      customer_first_name,
      customer_last_name,
      customer_email,
      services,
      note,
    },
    (response) => {
      if (response && response.status === "OK") {
        callBack(response.id);
      } else callBack(null);
    },
    (error) => {
      //handle error
      console.warn("Error////: " + error);
      callBack(false);
    }
  );
}

function toJSONLocal (date) {
  var local = new Date(date);
  local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return local.toJSON().slice(0, 10);
}

async function getUserAppointments(userId, callBack) {

  var date = new Date(), y = date.getFullYear(), m = date.getMonth();
  var firstDay = new Date(y, m, 1);

  const startDate = toJSONLocal(date);
  //const endDate = "2021-11-01";

  const parameters = {
    customers: userId,
    start_date: startDate,
    per_page: 20,
    //end_date: endDate,
  };

  getBookings(BOOKINGS_APPOINTMENT_PATH, parameters, callBack);
}

async function getAppointmentWithId(appointmentId, callBack) {
  const path = BOOKINGS_APPOINTMENT_PATH + "/" + appointmentId;
  getBookings(path, [], callBack);
}

async function getBookings(path, parameters, callBack) {
  Api.get(
    path,
    (bookingsData) => {
      if (bookingsData && bookingsData.status === "OK" && bookingsData.items) {
        callBack(bookingsData.items);
      } else {
        callBack(null);
      }
    },
    (error) => {
      //handle error
      console.warn("Error login: " + error);
      callBack(null);
    },
    parameters
  );
}

async function updateAppointment(id, status, note, callBack) {
  const path = BOOKINGS_APPOINTMENT_PATH + "/" + id;
  Api.put(
    path,
    {
      status,
      note,
    },
    (response) => {
      const status = response.status;
      callBack(status);
    },
    (error) => {
      //handle error
      console.warn("Error////: " + error);
      callBack(false);
    }
  );
}

const SalonService = {
  //checkIfTokenExist,
  loginToSalon,
  logout,
  getCoachAvailability,
  getCoachExternalBusyByMonth,
  bookAppointment,
  getUserAppointments,
  getAppointmentWithId,
  updateAppointment,
};

export default SalonService;
