import React from "react";

import { Box, makeStyles, Paper, Typography } from "@material-ui/core";
import MatchBox from "../MatchBox";

const useStyles = makeStyles((theme) => ({
  matchesCard: {
    borderRadius: 30,
    padding: 0,
    overflow: "hidden",
  },
  matchesTopBox: {
    height: 40,
    //backgroundColor: theme.palette.primary.main,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  matchesTitle: {
    fontSize: "1.3rem",
    fontWeight: 100,
    textAlign: "center",
    color: "black",
  },
  matchesDescription: {
    fontSize: "1.0rem",
    color: "black",
    fontWeight: 400,
    textAlign: "center",
  },
  percentage: {
    fontSize: "2.8rem",
    color: theme.palette.primary.main,
    fontWeight: 800,
    textAlign: "center",
  },
  percentageDescription: {
    fontSize: "0.7rem",
    color: "black",
    textAlign: "center",
  },
}));

const MatchesD = ({ fullName, percentage, matches }) => {
  const classes = useStyles();
 //console.log(percentage);
  
  // let name = fullName.split(" ")[0];
  return (
    <Paper className={classes.matchesCard} elevation={1}>
      <Box className={classes.matchesTopBox}>
        <Typography className={classes.matchesTitle}>
          In diesen Bereichen passt ihr super zueinander
        </Typography>
      </Box>
      <Box flexDirection="row" p={1}>
        {/* <Box width="40%" p={1}>
          <Typography className={classes.percentage}>{percentage}%</Typography>
          <Typography className={classes.percentageDescription}>
            Aufgrund deiner Antworten im Fragebogen konnten wir eine
            Übereinstimmung von 98% mit Hanna ermitteln.
          </Typography>
        </Box> */}
        <Box
          paddingRight={0}
          marginBottom={1}
          display="flex"
          minHeight={120}
          justifyContent="space-around"
          flexDirection="row"
        >
          {matches.map((match) => (
            <MatchBox
              key={match.id}
              icon={match.icon.white}
              title={match.name}
            />
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default MatchesD;
