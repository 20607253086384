import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
// import { useMediaQuery } from "react-responsive";

// import { useTranslation } from "react-i18next";
// import WithLoading from "../../hoc/WithLoading";

import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Box, CircularProgress, Typography, Paper, Link } from "@material-ui/core";

import { Button, Grid } from "@mui/material";
import AppointmentDetailsItem from "../../components/appointments/AppointmentDetailsItem";

import SalonService, { appointmentStatus } from "../../services/SalonService";
import { GlobalContext } from "../../store/contexts/Contexts";
import { useTracking } from "../../utils/tracking";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    textAlign: "center",
    marginTop: 20,
    marginBottom: 30,
    fontSize: "1.5rem",
    fontWeight: 900,
  },
  subtitle: {
    textAlign: "center",
    marginTop: 5,
    marginBottom: 20,
    fontSize: "1.0rem",
    fontWeight: 400,
  },
  terminTxt: {
    marginBottom: 10,
  },
  status: {
    marginLeft: 10,
    fontSize: "1.0rem",
    fontWeight: 600,
  },
  infoTitle: {
    marginTop: 20,
    fontSize: "1.0rem",
    fontWeight: 600,
  },
  okButton: {
    width: "30%",
    // color: "#FBBC42",
    fontSize: "0.9rem",
    fontWeight: 800,
    
    textTransform: "none",
  },
  cancelButton: {
    width: "30%",
    color: theme.palette.primary.main,
    fontSize: "0.6rem",
    fontWeight: 500, 
    
    textTransform: "none",
  },
}));

export default function AppointmentDetails() {
  //   const { goBack } = useContext(GlobalContext);
  const { coachesState, usersState } = useContext(GlobalContext);
  const [booking, setBooking] = useState(null);
  const [infosviaemail, setInfosviaemail] = useState(null);
  const [coach, setCoach] = useState(null);
  const [headerText, setHeaderText] = useState({ title: "", subtitle: null });

  const location = useLocation();
  const classes = useStyles();
  const history = useHistory();


  const {trackPage} = useTracking();
  useEffect(() => {
    trackPage()
  }, [])

  // const params = useParams();
  // const bookingIdSlug = params.bookingId;

  const { bookingId, appointmentConfirmed } = location.state;

  

  //   const isMobileDevice = useMediaQuery({
  //     query: "(max-device-width: 480px)",
  //   });

  useEffect(() => {
    if (bookingId) {
      SalonService.getAppointmentWithId(bookingId, (items) => {
        if (items) {
         //console.log("items", items);

          setBooking(items[0]);
        }else{
          setInfosviaemail("testuser has no access to bookings");
        }
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    handleHeaderText();
    const newcoach = getCoachByBooking(booking);
    if (newcoach) setCoach(newcoach);
    // eslint-disable-next-line
  }, [booking,infosviaemail]);


  const getCoachByBooking = (booking) => {

    if (!booking || !booking.services || !booking.services[0]){
      return null;
    }

    const assistentId = booking.services[0].assistant_id;
    if (!coachesState.coaches) {
      return;
    }
    if (assistentId) {
      const findCoach = coachesState.coaches.filter(
        (c) => c && c.acf && c.acf.coach_assistent === assistentId
      );
      const coach = findCoach.length > 0 ? findCoach[0] : null;
      return coach;
    }

  }



  const handleHeaderText = () => {
    if (!booking) return;
    var _title = "";
    var _subtitle = null;
    var title = coach && coach.title ? coach.title.rendered : "";
    if (appointmentConfirmed) {
      _title = "Buchung erfolgreich";
      _subtitle = "Eine Email von uns ist zu dir unterwegs.";
    } else {
      const now = new Date();
      if (getDateFromBooking(booking) < now) {
        _title = "Mein vergangener Termin am";
      } else {
        _title = "Mein Termin am";
      }
    }
    setHeaderText({ title: _title, subtitle: _subtitle });
  };

  const getDateFromBooking = (booking) => {
    const date = new Date(booking.date + " " + booking.time);
    return date;
  };

  const UserData = () => {
    return (
      <Box display="flex" flexDirection="column">
        
        

              
      </Box>
    );
  };

  const DetailsComponent = () => {
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography color="primary" className={classes.title}>{headerText.title}</Typography>
          {headerText.subtitle && (
            <Typography className={classes.subtitle}>
              {headerText.subtitle}
            </Typography>
          )}
        </Grid>
       
        <Grid item>
          <AppointmentDetailsItem booking={booking} coach={coach} />
        </Grid>
        {/* <Grid item>
          <Box display="flex" flexDirection="row" marginTop={3}>
            <Typography>Status: </Typography>
            <Typography className={classes.status}>
              {appointmentStatus(booking.status)}
            </Typography>
          </Box>
        </Grid> */}
        <Grid item>
          <UserData />
        </Grid>
        <Grid item>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={6}
          >
            <Button
              variant="outlined"
              className={classes.cancelButton}
              style={{
                color: "grey",
                borderColor: "grey",
                textTransform: "none",
                borderRadius: 10,
                fontWeight: 800,
              }}
              onClick={() =>
                history.push({
                  pathname: "/cancel-appointment",
                  state: bookingId,
                })
              }
            >
              Termin Absagen
            </Button>
          </Box>
        </Grid>
        <Grid item>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={0}
          >
            {usersState.loggedIn && <Link
              variant="contained"
              // color="primary"
              className={classes.okButton}
              style={{
                color: "lightgrey",
                backgroundColor: "white",
                textTransform: "none",
                border: "none",
                textAlign: "center",
              }}
              onClick={() => history.push("/my-appointments")}
            >
              Zurück Zur Übersicht
            </Link>}
          </Box>
        </Grid>
        
      </Grid>
    );
  };

  return (
    <Container className={classes.root}>
      <Box maxWidth="600px" minWidth="40vw">
        {booking ? (
          <DetailsComponent />
        ) :
        infosviaemail ?
          <div> Vielen Dank! Sie erhalten eine Email! </div>
        :
        (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="30vh"
          >
            <CircularProgress disableShrink />
          </Box>
        )}
      </Box>
    </Container>
  );
}
