import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Box, Button, Grid, Typography } from "@material-ui/core";
import { TYPE_ONE } from "../../data/QuestionsData";

const useStyles = makeStyles((theme) => ({
  middleGrid: {
    width: "100%",
    //minHeight: "75vh",
  },
  iconBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  icon: {
    width: "45px",
    margin: "7px",
    display: "flex",
    alignItems: "center",
  },
  title: {
    paddingLeft: "6vw",
    paddingRight: "6vw",
    textAlign: "center",
    fontSize: "1.0rem",
    fontWeight: 800,
  },
  answers: {
    width: "100%",
    textAlign: "center",
    height: "100%",
    paddingTop: 30,
    paddingBottom: 30,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "30px 30px 0px 0px",
    marginTop: 30,

  },
  answerBtn: {
    color: "white", //theme.palette.primary.main,
    //backgroundColor: "white",
    
    //fontWeight: 800,
    fontSize: "0.9rem",
    margin: "1.0vh",
    width: "80%",
    //height: 54,
    borderRadius: "2vh",
    borderColor: theme.palette.primary.light,
    borderWidth: 1,
    borderStyle: "solid",
    textTransform: "none",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  answerSelected: {
    color: "white",
    backgroundColor: theme.palette.primary.main + "!important",
  },
  leftIconBox: {
    backgroundColor: theme.palette.primary.main,
    border: "1px solid white",
    width: 15,
    height: 15,
    marginLeft: 8,
    marginRight: 5,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
  },
  leftIconBoxSelected: {
    backgroundColor: "white !important",
  },
  leftIconText: {
    color: "white",
    fontWeight: 800,
    fontSize: "1.3rem",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
  },
  leftIconTextSelected: {
    color: theme.palette.primary.main + "!important",
  },
  subjectIcon: {
    width: 36,
    marginLeft: 10,
    marginRight: 8,
    display: "flex",
    alignItems: "center",
  },
}));

export default function QuestionTypeOne({
  question,
  previousAnswer,
  callBack
}) {
  const classes = useStyles();

  function isAnswerSelected(ans) {
    return previousAnswer != null && previousAnswer.answer.value === ans.value;
  }


  const ButtonIcon = ({ answer, answerIndex }) => {
    return (
      <Box
        className={`${classes.leftIconBox} ${
          isAnswerSelected(answer) && classes.leftIconBoxSelected
        }`}
      >
        
      </Box>
    );
  };

  return (
    <Grid
      item
      container
      className={classes.middleGrid}
      justify="center"
      alignContent="space-around"
      spacing={0}
    >
      
      <Grid item style={{minHeight: "10vh", paddingTop: 10}}>
        <Typography className={classes.title}>{question.text}</Typography>
      </Grid>
      <Grid item xs={12} style={{minHeight: "70vh"}}>
        <Box className={classes.answers} flexDirection="column">
          {question.answers.map((answer, index) => {
            return (
              <Button
                key={index}
                startIcon={
                  
                    <ButtonIcon answer={answer} answerIndex={index} />
                  
                }
                size="medium"
                className={`${classes.answerBtn} ${
                  previousAnswer != null &&
                  previousAnswer.answer.value === answer.value &&
                  classes.answerSelected
                }`}
                onClick={() => callBack(answer)} //didSelectAnswer(answer)}
              >
                {answer.text}
              </Button>
              
            );
          })}
          {/* <Button onClick={prevQuestion}>Zurück</Button>  
          <Button onClick={nextQuestion}>Weiter</Button> */}
        </Box>
      </Grid>
    </Grid>
  );
}
