import React, { useContext, useEffect } from 'react'
import { GlobalContext } from '../../store/contexts/Contexts';
import { useTracking } from '../../utils/tracking';
import { Box, Button, ButtonBase, Grid, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

export default function Lerntypen() {

  const history = useHistory();
  const {trackPage, trackEvent} = useTracking();

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  
  useEffect(() => {
    trackPage()
  }, [])
  
  const {
    coachesState,
  } = useContext(GlobalContext);

 //console.log(coachesState)

  

  
  
  const keys = Object.keys(coachesState.result);
  const vals = Object.values(coachesState.result);
  // make list with indices and values
  const indexedTest = vals.map(function(e,i){return {ind: i, val: e}});
  // sort index/value couples, based on values
  indexedTest.sort(function(x, y){return x.val > y.val ? -1 : x.val == y.val ? 0 : 1});
  // make list keeping only indices
  const indicesSorted = indexedTest.map(function(e){return e.ind});
  const percentage = vals.map(val =>  Math.floor(100 * val / 21.0 ));
 //console.log(percentage,vals,indicesSorted);

  const getTitle = (answer) => {
    switch (answer){
      case "a": return "Der Procrasti-Party-Typ";
      case "b": return "Der Buchstaben-Highlander";
      case "c": return "Der gesellige Lerngruppler-Typ";
      case "d": return "Der markante Marker-Typ";
    }
  }
  const getAnswer = (answer) => {
    switch (answer){
      case "a":
       return (<Box>
        <p>
        Was wäre das Leben nur ohne Spaß! Party muss sein! Absolut. Die Frage ist nur wie OFT wir den Verlockungen
        nachgehen - wie leicht lassen wir uns  ablenken von dem, was uns wichtig ist. "Aufschieberitis" kennt jeder von uns, aber was in der Schule funktioniert hat, das ist im Studium ein No-Go.
        </p>
        <p>
        Dabei hilft es uns zu fragen, warum mir das Studium wichtig ist - MUSS ich lernen? Oder WILL ich lernen?
        Oft sagen wir, dass wir etwas tun müssen, dabei haben wir uns doch irgendwann mal dafür entschieden, das Studium anzufangen.
        </p>
        <p>
        Wenn ich mich erinnere, WARUM ich etwas mache, dann fällt es mir oft viel leichter, an einer Sache dran zu bleiben.
        </p>
        <p>
        Ein Impuls von den mentor-Coaches: Überlege dir, was du mit deinem Studium erreichen willst, wo du einmal sein willst und male dir richtig gut in deiner Vorstellung aus. Wie könnte das mal sein, wie wird es sich anfühlen? Oft hilft es, dass zusammen mit jemand anderen zu machen. Das kann ein Coach aber auch einfach ein guter Freund sein. 
        </p></Box>);
      case "b":
        return  (<Box>
          <p>Wenn du dich in ein Lehrbuch reinfuxt, kannst du alles um dich herum vergessen. WhatsApp und Emails checkst du nur ein bis zweimal im Quartal. Du weisst gar nicht mehr wie oft es dir passiert ist, dass deine WG-Mitbewohner deine Tür aufbrechen mussten, um zu prüfen, ob du noch atmest. Du liest pro Fach mindestens zwei tonnenschwere Lehrbücher. Rein interessehalber blätterst du zum Frühstück durch die Standardwerken fachärztlicher Literatur. „Lernen mit allen Sinnen“ hältst du für neumodischen Firlefanz - lesen ist alles. Mit Kommilitonen tauschst du dich nur aus, wenn es denn unabwendbar ist. Stapel und Türme von Bücherbergen und Skripten rund um Bett und Schreibtisch herum - so fühlst du dich am wohlsten.
          </p>
          <p>
        Rückzug ist manchmal die beste Verteidigung und doch sind die meisten Einzelkämpfer nur in Rambo-Filmen erfolgreich. Teamwork und Austausch zeigen oft ganz neue Seiten auf, die ein einzelner übersehen hätte. Bedenke: Das eigene Auge sieht sich nicht. 
        </p>
          <p>
        Ein Impuls von unseren mentor-Coaches: Um wirklich voran zu kommen - im Studium und im eigenen Leben, ist Feedback und Aussenansicht immens wichtig. Reflektion funktioniert nur mit einem Spiegel und für alles Körperliche sind die aus Glas - für unser Wissen und unsere Fähigkeiten bestehen die Spiegel aus menschlichen Zellhaufen, wie einem Freund oder freundlichem Coach. </p></Box>);
      case "c":
        return (<Box>
          <p>
          Deinen Schreibtisch im WG-Zimmer ist eigentlich nur ein Regal  für Topfpflanzen. Zum Lernen - natürlich in Gesellschaft deiner Lerngruppe - bevorzugst du öffentliche Orten, wie die Bib oder das Uni-Café. Diskussionen zu hochspezielle Fragestellungen sind dir die liebese Beschäftigung. Zwar stopfst du so nicht gerade die Lücken in deinem Wissensrepertoire – doch dafür übst du deine sozialen Kompetenzen, die für deine Zukunft viel wichtiger vorkommen als lebloses Faktenwissen. Vorlesungen sind für dich der ideale Ort um Kommilitonen zu treffen. In den Prüfung kümmert dich deine eigene Note gar nicht so arg - Hauptsache deine Lerngruppe ist ein erfolgreiches Team!
          </p>
          <p>
          Impuls der mentor-Coaches: Austausch in Lerngruppen ist wichtig, doch die Zeit für den eigenen Stoff und auch die Selbstreflektion im stillen Kämmerlein sind wichtig - sonst dreht sich in deine Lehrgruppe nur immer wieder um die gleichen Fragen und Antworten wie ein Talkabend mit Markus Lanz. 
          </p>
          <p>
       Wenn es dir nicht leicht fällt auch mal alleine zu lernen, dann kann es helfen feste Termine auszumachen  - bspw könnt ihr euch erst um 16 Uhr in der Lerngruppe treffen, dann hast du den ganzen Tag etwas, worauf du dich freuen kannst.
       </p>
         
        </Box>);
      case "d":
        return (<Box>
          <p>Ohne einen Palette Textmarker und einen dicken Schreibblock kannst du nicht lernen. Mit Liebe zum Detail unterstreichst du alles was dir wichtig erscheint. Das Wichtigste schreibst du gleich heraus und zeichnest Synopsen dazu. Wobei du es natürlich nicht lassen kannst auf dem abgeschriebenen sofort wieder alles zu unterstreichen, natürlich nach einem eigens kreierten Farbsystem. Damit das Rausschreiben in eigenen Worten funktioniert, musst du alles 100prozentig verstanden haben – was ein wenig anstrengend ist. Doch Dank eines fotografischen Gedächtnisses hast du in der Prüfung deine selbsterstellten Seiten wieder direkt vor Augen, so dass du die Lösungen quasi ablesen kannst. Du wirst dich 30 Jahre später noch an deine Aufschriebe erinnern!
          </p>
          <p>
        Du bist der typische visuelle Lerntyp. Und falsch ist dein Weg absolut nicht, wenn er für dich funktioniert: Relevantes optisch anreichern, rausschreiben und mit Bildern anreichern ist Reflektion pur und das hilft beim Lernen ungemein. Allerdings ist das extrem anstrengend und auf lange Sicht mit allen Materialien fast nicht machbar. 
        </p>
          <p>
        Ein Impuls von den mentor-Coaches: Weniger ist manchmal mehr - probiere deine Rausschreib-Phasen mit mündlichem Wiederholen in deiner Lerngruppe zu verknüpfen. Multimodal abgespeichertes Wissen ist zum einen noch besser abrufbar, und zum anderen kannst du so durch die Abwechslung ganz neue Motivations-Synapsen in dir aktivieren!</p>
        </Box>);
    }
  }

  return (
    <Box style={{maxWidth: 400, width: "100%", padding: 20, margin: "auto"}}>

      <Button
                     
                      variant="text"
                      
                      onClick={() => history.push("/")}
                    >
                      &lt; zurück zum Fragebogen
      </Button>

      <Typography variant="h4">Dein Ergebnis</Typography>
      
      {indicesSorted.map((k,i) => 
          (<p key={k}> 
           { i == 1 ? 
            <Box > 
              <Typography variant="h5">Sehr cool!</Typography>
            
              <p>Schön, dass du an unserem Fragebogen mitgemacht hast: Selbstreflektion ist immer der erste Schritt zur Besserung. Jetzt heisst es dranbleiben - doch mit der Selbstreflektion ist das so eine Sache: das eigene Auge sieht sich nicht! Und ein Coach ist wie ein Spiegel zum sichtbar machen deiner Stärken. 
              </p>
              <p>
                <b>mentor-app.de</b> ist der Coaching Anbieter der Gesundheitsbranche.
                Probiere es gleich aus: Die ersten 5 Teilnehmer erhalten ein freies Coaching bei<br/><a href="https://www.mentor-app.de" target="_blank">mentor-app.de</a><br/><br/>
                 Mehr Klarheit und Selbstbewusstsein warten auf dich. <a href="https://share.hsforms.com/1Siv-jWj7TzmJZ0XCP-IfsA4v9dl">&gt;&gt;Trag dich jetzt ein.&lt;&lt;</a>
              </p>
              
              <br/><br/>
            <Typography variant="h6">Und hier findest du noch die anderen Lern-Typen:</Typography>
            </Box> : ""}<br/>
          
            <b>{getTitle(keys[k])+ ":"}</b> <br/>
           <i>{"(Dein Match " + percentage[k] + "%) "}</i><br/><br/>
              {getAnswer(keys[k])}<br/></p>)
        )
      }

      <a href="https://share.hsforms.com/1Siv-jWj7TzmJZ0XCP-IfsA4v9dl">&gt;&gt; Gewinne jetzt einen Coach für dich!&lt;&lt;</a>
      
    </Box>
  )
}
