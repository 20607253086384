import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";

import { useMediaQuery } from "react-responsive";
import { GlobalContext } from "../store/contexts/Contexts";

import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,

} from "@material-ui/core";


import logo from "../assets/images/future-doctors.svg";

// const languageData = [
//   {
//     label: "English",
//     value: "en",
//   },
//   {
//     label: "German",
//     value: "de",
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: theme.spacing(2),
    color: "black",
    fontSize: "1.0rem",
    fontWeight: 800,
  },
  langButton: {},
  button: {
    color: theme.palette.grey[400],
    justifyContent: "right",
    height: "100%",
  },
  backBtn: {
    color: theme.palette.grey[400],
    justifyContent: "left",
    height: "100%",
  },
  title: {
    color: "black",
    fontSize: "1.3rem",
    fontWeight: 800,
  },
  toolbar: theme.mixins.toolbar,
}));

export default function Header({ title, onHeaderClick }) {

  const [anchorEl, setAnchorEl] = useState(null);
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const { coachesState, usersState, goBack } = useContext(GlobalContext);

  

  const classes = useStyles();
  

  return (
    <div className={classes.root}>
      <AppBar style={{ backgroundColor: "white",  }}>
        <img src={logo}  style={{height: 50, padding: 10}} />
      </AppBar>
    </div>
  );
}
