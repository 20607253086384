export const DO_LOGOUT = "DO_LOGOUT";
export const DO_LOGIN = "DO_LOGIN";
export const DONE_LOGIN = "DONE_LOGIN";
export const DO_LOADME = "DO_LOADME";
export const DONE_LOADME = "DONE_LOADME";

export const SAVING_CUSTOM_FIELDS = "SAVING_CUSTOM_FIELDS";
export const SAVED_CUSTOM_FIELDS = "SAVED_CUSTOM_FIELDS";
export const SET_CUSTOM_FIELDS = "SET_CUSTOM_FIELDS";

//export const ERROR_LOGIN = "ERROR_LOGIN";

export const usersInitialState = {
  token: null,
  username: null,
  password: null,
  userEmail: null,
  firstName: null,
  lastName: null,
  userId: null,
  fields: null,

  loading: false,

  savingCustomFields: false,

  loggedIn: false,
  error: false,

  // loadingme: false,
  // loadingmeerror: false,
};

export const UserReducer = (state, action) => {
  const action_ = action && action.type ? action : {};
  action_.payload = action_.payload ? action_.payload : {};
  //console.log("UserReducer", action_);

  switch (action_.type) {
    case DO_LOGOUT:
      return {
        ...state,
        ...usersInitialState,
      };

    case DO_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case DONE_LOGIN:
      return {
        ...state,
        loading: false,
        token: action_.payload.token,
        loggedIn: action_.payload.token != null,
        userEmail: action_.payload.user_email,
        firstName: action_.payload.first_name ,
        lastName: action_.payload.last_name ,
        userId: action_.payload.id,
        error: action_.payload.token == null,
      };
    case SAVING_CUSTOM_FIELDS:
      return {
        ...state,
        savingCustomFields: true,
      };
    case SET_CUSTOM_FIELDS:
      return {
        ...state,
        savingCustomFields: false,
        fields: action_.payload,
      };

    default:
      return state;
  }
};
