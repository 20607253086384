import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

import logo from "../../assets/images/future-doctors.svg";
import imgStripe from '../../assets/images/future-docs-stripe.png';
import mentorLogo from "../../assets/images/Mentor_Logo_Claim.png";
import { Typography } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  typoMain:  {
    color: theme.palette.secondary.main,
  }
}));


export default function StartQuestions() {

  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      style={{ minHeight: '100vh' }}
    >
    
      <Grid item xs={12} 
          style={{
            display: "flex",
            alignItems: "center", 
            textAlign: "center",
            flexDirection: "column"
          }}> 
        <img src={logo}  
          style={{height: 60, padding: 10, marginTop: 30}} />
        <img
          src={imgStripe}
          style={{
            maxWidth: "390px",
            width: "100vw",
            marginTop: 20
          }}
          
          alt=""
        />
        <Typography
          
          style={{
            marginTop: 25,
            fontSize: "2.8rem",
            fontWeight: 800,
            maxWidth: "390px",
            
          }} >
            DU<br/>WILLST<br/>WISSEN<br/>WELCHER<br/>
            <Typography
              className={classes.typoMain}
              style={{
                fontSize: "2.8rem",
                fontWeight: 800,
                
                maxWidth: "390px",
                
              }} >LERNTYP
             </Typography>
             DU BIST?

        </Typography>
      <Button 
        style={{
          marginTop: 30,
          borderRadius: 20,
          padding: 5,
          fontSize: "1.3rem",
          fontWeight: 600,
          width: "100%",
          minWidth: 200,
          maxWidth:300,
          color: "white",
          font : "Montserrat, Sans-serif",
          textTransform: "none",
          // marginLeft: "30px",
        }}
        variant="contained"
        color="secondary"
        onClick={() => history.replace("/fragebogen")}>Jetzt loslegen!</Button>

          <Typography
              
              style={{
                fontSize: "1rem",
                fontWeight: 600,
                paddingTop: 30,
                maxWidth: "390px",
                
              }} >
              powered by<br/>
              <a href="https://www.future-doctor.de" 
                  target="_blank"
                  style={{textDecoration: "none"}}
                  >
                  {/* <span style={{color:"#00D1D6", fontSize:"18px", fontWeight:500}}>future</span>
                <b>
                  <span style={{color:"#000", fontSize:18, fontWeight:700}}>DOCTOR</span>
                  <span style={{color:"#ff0e63", fontSize:18, fontWeight:700}}>.</span>
                </b> */}
                <img src={logo}  
                    style={{height: 30,  marginTop: 30, marginBottom: -5}} />
              </a> &nbsp;&amp;<a href="https://www.mentor-app.de" target="_blank"><img src={mentorLogo} style={{height:50 , marginBottom: -18}} /></a>


             </Typography>
        
      </Grid>   
     
  </Grid> 
    
  )
}
